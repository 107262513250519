export const country_pages = [
  {
    country_name: 'Malaysia',
    country_code: 'MY',
    endpoint: '/malaysia',
  },
  {
    country_name: 'Senegal',
    country_code: 'SN',
    endpoint: '/senegal',
  },
  {
    country_name: 'Benin',
    country_code: 'BJ',
    endpoint: '/benin',
  },
  {
    country_name: 'Italy',
    country_code: 'IT',
    endpoint: '/italy',
  },
  {
    country_name: 'Saudi Arabia(KSA)',
    country_code: 'SA',
    endpoint: '/sa',
  },
  {
    country_name: 'Oman',
    country_code: 'om',
    endpoint: '/oman',
  },
  {
    country_name: 'Qatar',
    country_code: 'qa',
    endpoint: '/qatar',
  },
  {
    country_name: 'Lebanon',
    country_code: 'lb',
    endpoint: '/lebanon',
  },
  {
    country_name: 'Jordan',
    country_code: 'jo',
    endpoint: '/jordan',
  },
  {
    country_name: 'Egypt',
    country_code: 'eg',
    endpoint: '/egypt',
  },
  {
    country_name: 'Kuwait',
    country_code: 'kw',
    endpoint: '/kuwait',
  },
]
