import React, { useState, useRef, useEffect } from 'react'
import Transition from '@/utils/Transition'
import ReactCountryFlag from 'react-country-flag'
import ALink from 'constant/ALInk'
import { country_pages } from 'data/country_pages'

function CountryMenu({ btn_title }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const trigger = useRef(null)
  const dropdown = useRef(null)

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target))
        return
      setDropdownOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return
      setDropdownOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="flex items-center truncate">
          <span className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">
            {btn_title ? btn_title : 'Countries'}
          </span>

          {dropdownOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
          )}
        </div>
      </button>

      <Transition
        className="origin-top-left z-10 absolute bottom-full left-0  w-[200px]  sm:w-[345px] md:w-[500px] bg-white      py-5 px-3 md:px-5 rounded  shadow-xl  mb-3  "
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul className="z-20 grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3   gap-2">
            {country_pages?.map((item) => {
              return (
                <li>
                  <ALink className=" w-[150px] flex items-start gap-2 p-1" href={item.endpoint}>
                    <ReactCountryFlag countryCode={item.country_code} svg />
                    <span className=" leading-none whitespace-pre-wrap">{item.country_name}</span>
                  </ALink>
                </li>
              )
            })}
          </ul>

          <div className=" z-10  w-[15px] h-[15px] bg-white absolute   left-0  transform rotate-45  -bottom-2  ml-4     shadow-lg " />
        </div>
      </Transition>
    </div>
  )
}

export default CountryMenu
