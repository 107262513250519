import Link from 'next/link'

export default function ALink({ children, className, style, click_func, href, target }) {
  function defaultFunction(e) {
    if (href == '#') {
      e.preventDefault()
    }
    if (click_func) {
      click_func()
    }
  }

  return href ? (
    <Link href={href}>
      <a
        className={className}
        style={style}
        onClick={(e) => defaultFunction(e)}
        target={target ? target : '_self'}
      >
        {children}
      </a>
    </Link>
  ) : (
    <a className={className} style={style} onClick={(e) => defaultFunction(e)}>
      {children}
    </a>
  )
}
